import axios from 'axios';
import store from '../../../store/index'
import {backendApi} from "../../backend-api-sr"

export const salesActivity = (() =>{
    const insertData = ( async (subPath, reqBody) => {
        var url = '/api/sr/CustomerVisit/store'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })
    return {insertData};
})()